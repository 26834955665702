import React, { Component } from "react";
import Layout from "../components/Layout";

export class NotFound extends Component {
  render() {
    return (
      <Layout>
        <div>
          <h1>Page Not Found</h1>
        </div>
      </Layout>
    );
  }
}
